import React, { useEffect, useState, useRef } from "react";
import axios, { toFormData } from "axios";
import { API_URL } from "../../const";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlinePrinter } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Swal from "sweetalert2";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { RxCross2 } from "react-icons/rx";
import ReactToPrint from "react-to-print";

const Dashboard = () => {
  let componentRef = useRef();
  const [employeeList, setEmployeeList] = useState([]);
  const [nonRoutingTaskList, setNonRoutingTaskList] = useState([]);
  const [routingTaskList, setRoutingTaskList] = useState([]);
  const [routingTabs, setRoutingTabs] = useState([]);
  const [nonRoutingTabs, setNotRoutingTabs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [noteList, setNoteList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [searchTagBy, setSearchTagBy] = useState("");
  const [mode, setMode] = useState(1);
  const [showModal, setShowModel] = useState(false);
  const [modeChild, setModeChild] = useState(1);

  const getClient = (year) => {
    let temp = [];
    axios.post(API_URL.CLIENT_LIST, { year: year }).then((res) => {
      if (res.data.status === 1) {
        res.data.data.map((element) => {
          temp.push({
            person_one_no: element.person_one_no,
            email_one: element.email_one,
            firm_name: element.firm_name,
          });
        });
        getOtherContact(temp);
      }
    });
  };

  const getOtherContact = (temp) => {
    axios.post(API_URL.CONTACTS_LIST, {}).then((res) => {
      if (res.data.status === 1) {
        res.data.data.map((element) => {
          temp.push({
            person_one_no: element.contact_no,
            email_one: element.email,
            firm_name: element.name,
          });
        });
        setClientList(temp);
      }
    });
  };

  const listData = () => {
    setLoading(true);
    axios.post(API_URL.NOTES_REMINDER, {}).then(
      (res) => {
        if (res.data.status === 1) {
          setNoteList(res.data.data);
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
        setLoading(false);
      }
    );
  };

  const closeNote = (res) => {
    Swal.fire({
      title: "Are you sure you want to close this?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(API_URL.NOTES_UPDATE, {
            id: res.id,
            status: 1,
          })
          .then((response) => {
            if (response.data.status === 1) {
              listData();
            } else {
              if (response.data && response.data.data) {
                toast.error("something Went Wromg.....", {
                  autoClose: 3000,
                  position: "top-center",
                });
              }
            }
          });
      }
    });
  };

  const updateReminderDate = (id, date) => {
    let url = API_URL.NOTES_UPDATE;
    axios
      .post(url, {
        id: id,
        date: date ? moment(date).format("YYYY-MM-DD") : null,
      })
      .then((response) => {
        if (response.data.status === 1) {
          listData();
        } else {
          if (response.data && response.data.data) {
            toast.error("something Went Wromg.....", {
              autoClose: 3000,
              position: "top-center",
            });
          }
        }
      });
  };

  const isToday = (date) => {
    if (moment().format("YYYY-MM-DD") === date) {
      return "red";
    } else {
      return null;
    }
  };

  const filterClient = () => {
    let sel = clientList.filter(
      (d) => d.firm_name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    );
    return sel;
  };

  const getEmployee = () => {
    axios.post(API_URL.EMP_LIST, {}).then((res) => {
      let employess = [];
      res.data.data.map((element) => {
        employess.push({ value: element.name, label: element.name });
      });
      setEmployeeList(employess);
    });
  };

  const getTask = () => {
    setLoading1(true);
    axios.post(API_URL.TASK_DASHBOARD, {}).then(
      (res) => {
        if (res.data.status === 1) {
          setRoutingTaskList(res.data.data.routing);
          setNonRoutingTaskList(res.data.data.non_routing);
          let tabs = [];
          res.data.data.routing.map((element) => {
            if (element.steps) {
              let coSteps = [];
              Object.keys(element.steps).forEach((rowK) => {
                coSteps.push(element.steps[rowK]);
              });
              element.steps = coSteps;
            }
            let index = tabs.findIndex((d) => d === element.lookup.key_name);
            if (index === -1) {
              tabs.push(element.lookup.key_name);
            }
          });
          setRoutingTabs(tabs);
          let tabs1 = [];
          res.data.data.non_routing.map((element) => {
            if (element.steps) {
              let coSteps = [];
              Object.keys(element.steps).forEach((rowK) => {
                coSteps.push(element.steps[rowK]);
              });
              element.steps = coSteps;
            }
            let index = tabs1.findIndex((d) => d === element.lookup.key_name);
            if (index === -1) {
              tabs1.push(element.lookup.key_name);
            }
          });
          setNotRoutingTabs(tabs1);
        }
        setLoading1(false);
      },
      (error) => {
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
        setLoading1(false);
      }
    );
  };

  const filterRoutingTask = (key) => {
    return routingTaskList.filter(
      (d) => d.lookup.key_name === routingTabs[key]
    );
  };

  const filterNonRoutingTask = (key) => {
    return nonRoutingTaskList.filter(
      (d) => d.lookup.key_name === nonRoutingTabs[key]
    );
  };

  const styles = {
    menu: (basestyle, state) => ({
      ...basestyle,
      zIndex: 999,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "white",
        color: "#000",
      };
    },
  };
  const handleOpenPrintModel = () => {
    setShowModel(true);
  };

  const filterNoteData = () => {
    if (searchTagBy && searchTagBy.value) {
      return noteList.filter((d) => d.tag_by === searchTagBy.value);
    }
    return noteList;
  };

  useEffect(() => {
    listData();
    getEmployee();
    getClient();
    getTask();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12 d-flex">
        <div className="card flex-fill team-lead shadow-sm">
          <div className="d-flex card-header align-items-center justify-content-between">
            <div>
              <h4 className="card-title mb-0 d-inline-block">Panding Task</h4>
            </div>
            <div>
              <button
                style={{ borderBottom: "0px" }}
                className="btn btn-danger text-white ctm-border-radius float-right mr-3"
                onClick={handleOpenPrintModel}
              >
                <AiOutlinePrinter size={25} />
              </button>
            </div>
          </div>
          <div className="card-body">
            {loading1 ? (
              <Oval
                height={60}
                width={60}
                color="#7539ff"
                wrapperStyle={{ justifyContent: "center" }}
                wrapperClassName=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#7539ff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              <>
                <div
                  className="flex-row list-group list-group-horizontal-lg"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className="active list-group-item"
                    id="v-pills-home-tab"
                    style={{ padding: "3px 14px 3px 15px" }}
                    data-toggle="pill"
                    href="reviews.html#v-pills-home"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                    onClick={() => {
                      setMode(1);
                      setModeChild(1);
                    }}
                  >
                    Non Routing
                    <span className="badge badge-success ml-2">
                      {nonRoutingTaskList.length}
                    </span>
                  </a>
                  <a
                    className="list-group-item"
                    id="v-pills-home-tab"
                    style={{ padding: "3px 14px 3px 15px" }}
                    data-toggle="pill"
                    href="reviews.html#v-pills-home"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                    onClick={() => {
                      setMode(2);
                      setModeChild(1);
                    }}
                  >
                    Routing
                    <span className="badge badge-success ml-2">
                      {routingTaskList.length}
                    </span>
                  </a>
                </div>
                <div className="mt-3">
                  {mode === 1 && (
                    <div
                      className="flex-row list-group list-group-horizontal-lg"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      {nonRoutingTabs.map((tab, key) => {
                        return (
                          <a
                            key={key}
                            className={`${
                              modeChild === key + 1 && "active"
                            } list-group-item`}
                            id="v-pills-home-tab"
                            style={{ padding: "3px 14px 3px 15px" }}
                            data-toggle="pill"
                            href="reviews.html#v-pills-home"
                            role="tab"
                            aria-controls="v-pills-home"
                            aria-selected="true"
                            onClick={() => {
                              setModeChild(key + 1);
                            }}
                          >
                            {tab}
                            <span className="badge badge-success ml-2">
                              {filterNonRoutingTask(key).length}
                            </span>
                          </a>
                        );
                      })}
                    </div>
                  )}
                  {mode === 2 && (
                    <div
                      className="flex-row list-group list-group-horizontal-lg"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      {routingTabs.map((tab, key) => {
                        return (
                          <a
                            key={key}
                            className={`${
                              modeChild === key + 1 && "active"
                            } list-group-item`}
                            id="v-pills-home-tab"
                            style={{ padding: "3px 14px 3px 15px" }}
                            data-toggle="pill"
                            href="reviews.html#v-pills-home"
                            role="tab"
                            aria-controls="v-pills-home"
                            aria-selected="true"
                            onClick={() => {
                              setModeChild(key + 1);
                            }}
                          >
                            {tab}
                            <span className="badge badge-success ml-2">
                              {filterRoutingTask(key).length}
                            </span>
                          </a>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="mt-3">
                  {mode === 1 && (
                    <table className="table custom-table dashboard-table table-bordered">
                      <thead>
                        <tr>
                          <th className="text-center">Step</th>
                          <th>What ?</th>
                          <th>How ?</th>
                          <th>Client</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterNonRoutingTask(modeChild - 1).map((res, key) => {
                          return (
                            <tr key={key}>
                              <td
                                style={{ width: "5%" }}
                                className="text-center"
                              >
                                {res.step_no} - {res.employee.name}
                              </td>
                              <td
                                style={{ width: "15%", verticalalign: "top" }}
                              >
                                {res.for_what}
                              </td>
                              <td
                                style={{ width: "50%", verticalalign: "top" }}
                              >
                                {res.for_how}
                              </td>
                              <td
                                style={{
                                  width: "25%",
                                  padding: "0px",
                                  verticalalign: "top",
                                }}
                              >
                                {res.steps && res.steps.length > 0 && (
                                  <>
                                    {res.steps.map((row, k) => {
                                      return (
                                        <div
                                          className="d-block px-1"
                                          style={{ borderBottom: "1px solid" }}
                                          key={key + "" + k}
                                        >
                                          {row.client.firm_name}
                                          <div
                                            className="mb-1"
                                            style={{ lineHeight: "0" }}
                                          >
                                            <span className="badge badge-success mr-2">
                                              {row.client.person_one_no}
                                            </span>
                                            <span className="badge badge-success">
                                              {row.client.email_one}
                                            </span>
                                          </div>
                                          <div
                                            className="mb-1"
                                            style={{ lineHeight: "0" }}
                                          >
                                            <span className="badge badge-warning mr-2">
                                              {row.client.mehtaji_no}
                                            </span>
                                            <span className="badge badge-warning">
                                              {row.client.mehtaji_email}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                  {mode === 2 && (
                    <table className="table custom-table dashboard-table table-bordered">
                      <thead>
                        <tr>
                          <th className="text-center">Step</th>
                          <th>What ?</th>
                          <th>How ?</th>
                          <th>Client</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterRoutingTask(modeChild - 1).map((res, key) => {
                          return (
                            <tr key={key}>
                              <td
                                style={{ width: "5%" }}
                                className="text-center"
                              >
                                {res.step_no} - {res.employee.name}
                              </td>
                              <td style={{ width: "15%" }}>{res.for_what}</td>
                              <td style={{ width: "50%" }}>{res.for_how}</td>
                              <td style={{ width: "25%", padding: "0px" }}>
                                {res.steps && res.steps.length > 0 && (
                                  <>
                                    {res.steps.map((row, k) => {
                                      return (
                                        <div
                                          className="d-block px-1"
                                          style={{ borderBottom: "1px solid" }}
                                          key={key + "" + k}
                                        >
                                          {row.client.name} <span className="badge badge-primary mr-2">
                                              {row.task_view}
                                            </span>
                                          <div
                                            className="mb-1"
                                            style={{ lineHeight: "0" }}
                                          >
                                            <span className="badge badge-success mr-2">
                                              {row.client.person_one_no}
                                            </span>
                                            <span className="badge badge-success">
                                              {row.client.email_one}
                                            </span>
                                          </div>
                                          <div
                                            className="mb-1"
                                            style={{ lineHeight: "0" }}
                                          >
                                            <span className="badge badge-warning mr-2">
                                              {row.client.mehtaji_no}
                                            </span>
                                            <span className="badge badge-warning">
                                              {row.client.mehtaji_email}
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="search-section">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search By Client"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon1">
                <BsSearch />
              </span>
            </div>
          </div>
        </div>
      </div>
      {searchText && (
        <div className="card-section">
          {filterClient().map((res, key) => {
            return (
              <div className="card" key={key}>
                <div className="titles">{res.firm_name}</div>
                <div>{res.person_one_no}</div>
                <div>{res.email_one}</div>
              </div>
            );
          })}
        </div>
      )}
      <div className="col-md-12 d-flex">
        <div className="card flex-fill team-lead shadow-sm">
          <div className="card-header">
            <h4 className="card-title mb-0 d-inline-block">Reminder</h4>
          </div>
          <div className="card-body">
            {loading ? (
              <Oval
                height={60}
                width={60}
                color="#7539ff"
                wrapperStyle={{ justifyContent: "center" }}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#7539ff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              <>
                <div className="row">
                  <div className="col-md-3">
                    <label className="mb-0 form-label">Search Tag By</label>
                    <Select
                      className="basic-single mb-3"
                      name="color"
                      styles={styles}
                      isClearable={true}
                      options={employeeList}
                      placeholder="Select"
                      value={searchTagBy}
                      onChange={(e) => {
                        setSearchTagBy(e);
                      }}
                    />
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table custom-table dashboard-table table-bordered">
                    <thead>
                      <tr>
                        <th>Reminder Date</th>
                        <th>Tag By</th>
                        <th>Client Name</th>
                        <th>Mobile Number</th>
                        <th>Email</th>
                        <th>Firm Type</th>
                        <th>Note Type</th>
                        <th>Note</th>
                        <th>Created By</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterNoteData().map((res, key) => {
                        return (
                          <tr
                            key={key}
                            className={`${
                              isToday(res.date) && "today-reminder"
                            }`}
                          >
                            <td>
                              <div
                                className="date"
                                style={{ cursor: "pointer" }}
                              >
                                <DatePicker
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="Select Date.."
                                  selected={
                                    res.date ? new Date(res.date) : null
                                  }
                                  customInput={
                                    <div className="date">
                                      {moment(res.date).format("DD/MM/YYYY")}
                                    </div>
                                  }
                                  onChange={(date) => {
                                    const copyData = [...noteList];
                                    copyData[key].date = date;
                                    setNoteList(copyData);
                                    updateReminderDate(res.id, date);
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <span
                                class="badge badge-primary mr-1"
                                style={{
                                  backgroundColor: "rgb(81, 25, 137)",
                                  fontSize: "14px",
                                }}
                              >
                                {res.tag_by}
                              </span>
                            </td>
                            <td>{res.client?.firm_name}</td>
                            <td>{res.client?.person_one_no}</td>
                            <td>{res.client?.email_one}</td>
                            <td>{res.firm_type}</td>
                            <td>{res.note_type}</td>
                            <td
                              dangerouslySetInnerHTML={{ __html: res.notes }}
                            ></td>
                            <td>{res.user}</td>
                            <td>
                              <div className="action-section">
                                <button
                                  type="button"
                                  onClick={() => {
                                    closeNote(res);
                                  }}
                                  className="btn btn-sm btn-red btn-action-table "
                                >
                                  <AiOutlineCloseCircle
                                    color={isToday(res.date) && "white"}
                                    size={25}
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        className="step-modal"
        size="xl"
        show={showModal}
        onHide={() => setShowModel(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">Tasks</Modal.Title>
          <ReactToPrint
            content={() => componentRef}
            trigger={() => {
              return (
                <button
                  style={{ borderBottom: "0px" }}
                  className="btn btn-danger text-white ctm-border-radius float-right mr-3"
                >
                  <AiOutlinePrinter size={25} />&nbsp;Print
                </button>
              );
            }}
          />
          <RxCross2
            size={25}
            onClick={() => {
              setShowModel(false);
            }}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>
          <table
            className="table custom-table dashboard-table table-bordered"
            ref={(el) => (componentRef = el)}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid",
                    color: "black",
                    backgroundColor: "white",
                  }}
                  className="text-center"
                >
                  Step
                </th>
                <th
                  style={{
                    border: "1px solid",
                    color: "black",
                    backgroundColor: "white",
                  }}
                >
                  What ?
                </th>
                <th
                  style={{
                    border: "1px solid",
                    color: "black",
                    backgroundColor: "white",
                  }}
                >
                  How ?
                </th>
                <th
                  style={{
                    border: "1px solid",
                    color: "black",
                    backgroundColor: "white",
                  }}
                >
                  Client
                </th>
              </tr>
            </thead>
            {mode === 1 && <tbody>
              {nonRoutingTabs.map((tab, key) => {
                return (
                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        textAlign: "center",
                        fontSize: "16px",
                        fontWeight: "800",
                      }}
                    >
                      {tab}
                      <table>
                        <tbody>
                          {filterNonRoutingTask(key).map(
                            (res, key) => {
                              return (
                                <tr key={key}>
                                  <td
                                    style={{ width: "5%",borderLeft: 'none' }}
                                    className="text-center"
                                  >
                                    {res.step_no} - {res.employee.name}
                                  </td>
                                  <td
                                    style={{
                                      width: "15%",
                                      verticalalign: "top",
                                    }}
                                  >
                                    {res.for_what}
                                  </td>
                                  <td
                                    style={{
                                      width: "50%",
                                      verticalalign: "top",
                                    }}
                                  >
                                    {res.for_how}
                                  </td>
                                  <td
                                    style={{
                                      width: "25%",
                                      padding: "0px",
                                      borderRight:'none',
                                      verticalalign: "top",
                                    }}
                                  >
                                    {res.steps && res.steps.length > 0 && (
                                      <>
                                        {res.steps.map((row, k) => {
                                          return (
                                            <div
                                              className="d-block px-1"
                                              style={{
                                                borderBottom: res.steps.length - 1 !== k && "1px solid",
                                              }}
                                              key={key + "" + k}
                                            >
                                              {row.client.firm_name}
                                              <div
                                                className="mb-1"
                                                style={{ lineHeight: "0" }}
                                              >
                                                <span className="badge badge-success mr-2">
                                                  {row.client.person_one_no}
                                                </span>
                                                <span className="badge badge-success">
                                                  {row.client.email_one}
                                                </span>
                                              </div>
                                              <div
                                                className="mb-1"
                                                style={{ lineHeight: "0" }}
                                              >
                                                <span className="badge badge-warning mr-2">
                                                  {row.client.mehtaji_no}
                                                </span>
                                                <span className="badge badge-warning">
                                                  {row.client.mehtaji_email}
                                                </span>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                );
              })}
            </tbody>}

            {mode === 2 && <tbody>
              {routingTabs.map((tab, key) => {
                return (
                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        textAlign: "center",
                        fontSize: "16px",
                        fontWeight: "800",
                      }}
                    >
                      {tab}
                      <table>
                        <tbody>
                          {filterRoutingTask(key).map(
                            (res, key) => {
                              return (
                                <tr key={key}>
                                  <td
                                    style={{ width: "5%",borderLeft: 'none' }}
                                    className="text-center"
                                  >
                                    {res.step_no} - {res.employee.name}
                                  </td>
                                  <td
                                    style={{
                                      width: "15%",
                                      verticalalign: "top",
                                    }}
                                  >
                                    {res.for_what}
                                  </td>
                                  <td
                                    style={{
                                      width: "50%",
                                      verticalalign: "top",
                                    }}
                                  >
                                    {res.for_how}
                                  </td>
                                  <td
                                    style={{
                                      width: "25%",
                                      padding: "0px",
                                      borderRight:'none',
                                      verticalalign: "top",
                                    }}
                                  >
                                    {res.steps && res.steps.length > 0 && (
                                      <>
                                        {res.steps.map((row, k) => {
                                          return (
                                            <div
                                              className="d-block px-1"
                                              style={{
                                                borderBottom: res.steps.length - 1 !== k && "1px solid",
                                              }}
                                              key={key + "" + k}
                                            >
                                              {row.client.firm_name} <span className="badge badge-primary mr-2">
                                              {row.task_view}
                                            </span>
                                              <div
                                                className="mb-1"
                                                style={{ lineHeight: "0" }}
                                              >
                                                <span className="badge badge-success mr-2">
                                                  {row.client.person_one_no}
                                                </span>
                                                <span className="badge badge-success">
                                                  {row.client.email_one}
                                                </span>
                                              </div>
                                              <div
                                                className="mb-1"
                                                style={{ lineHeight: "0" }}
                                              >
                                                <span className="badge badge-warning mr-2">
                                                  {row.client.mehtaji_no}
                                                </span>
                                                <span className="badge badge-warning">
                                                  {row.client.mehtaji_email}
                                                </span>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                );
              })}
            </tbody>}
          </table>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Dashboard;
