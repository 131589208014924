import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import Swal from "sweetalert2";
import NoteModal from "../../component/modal/note-modal";
import TaskModal from "../../component/modal/task-modal";
import { AiOutlinePrinter } from "react-icons/ai";
import { FaTasks } from "react-icons/fa";
import { API_URL } from "../../const";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import { AiOutlineDelete } from "react-icons/ai";
import { GrNotes } from "react-icons/gr";
import ReactToPrint from "react-to-print";

const NonRoutine = () => {
  let componentRef = useRef();
  const [loading, setLoading] = useState(true);
  const [clientList, setClientList] = useState([]);
  const [nonroutingclientList, setNonRoutingClientList] = useState([]);
  const [allTask, setAllTask] = useState([]);
  const [shownotes, setShowNotes] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [options, setOptions] = useState();
  const [formData, setFormData] = useState({
    client_id: "",
    lookup_id: "",
  });

  const [filterData, setFilterData] = useState([
    { key: "name", value: "" },
    { key: "task", value: "" },
    { key: "number", value: "" },
    { key: "date", value: "" },
    { key: "status", value: "" },
  ]);

  const getClient = () => {
    axios.post(API_URL.CLIENT_LIST, {}).then((res) => {
      if (res.data.status === 1) {
        res.data.data.map((item) => {
          item.label = item.firm_name;
          item.value = item.id;
        });
        setClientList(res.data.data);
      }
    });
  };

  const getLookup = () => {
    axios
      .post(API_URL.LOOKUP_LIST, { key_type: ["Non Routing"] })
      .then((res) => {
        if (res.data.status === 1) {
          const rout = res.data.data.map((item) => {
            return {
              label: item.key_name,
              value: item.id,
            };
          });
          setOptions(rout);
        }
      });
  };

  const getNonRoutingClient = () => {
    axios.post(API_URL.NOT_ROUTING_LIST, {}).then((res) => {
      if (res.data.status === 1) {
        let data = res.data.data.map((element) => {
          return {
            ...element,
            name: element.client.firm_name,
            task: element.lookup.key_name,
            number: element.client.person_one_no,
            date: moment(element.created_at).format("DD/MM/YYYY hh:mm A"),
            status: element.status.toString(),
          };
        });
        setAllTask(data);
        sortStatusByData(res.data.data);
      }
      setLoading(false);
    });
  };

  const addUpdateSteps = (client_id, lookup_id) => {
    axios
      .post(API_URL.TASK_CLIENT, {
        client_id: client_id,
        lookup_id: lookup_id,
      })
      .then((res) => {
        if (res.data.status != 1) {
          toast.info("Task not available...");
        }
      });
  };

  const saveData = () => {
    let url = API_URL.NOT_ROUTING_SAVE;
    let body = { ...formData };
    axios.post(url, body).then((response) => {
      addUpdateSteps(body.client_id, body.lookup_id)
      if (response.data.status === 1) {
        getNonRoutingClient();
        setFormData({
          client_id: "",
          lookup_id: "",
        });
      } else {
        if (response.data && response.data.data) {
          toast.error("something Went Wromg.....", {
            autoClose: 3000,
            position: "top-center",
          });
        }
      }
    });
  };

  const deleteNonRoutingEmployee = (res) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(API_URL.NOT_ROUTING_DELETE, { id: res.id }).then((res) => {
          if (res.data.status === 1) {
            getNonRoutingClient();
            toast.success("Successfully Non Routing Task Deleted", {
              autoClose: 3000,
              position: "top-center",
            });
          } else {
            toast.error(res.data.data, {
              autoClose: 3000,
              position: "top-center",
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    getLookup();
    getClient();
    getNonRoutingClient();
  }, []);

  const handleShowNotes = (note) => {
    setCurrentRow({
      id: note.client.id,
      file_no: note.client.file_no,
      name: note.client.name,
    });
    setShowNotes(true);
  };

  const isValidForm = () => {
    return formData.client_id && formData.lookup_id;
  };

  useEffect(() => {
    let copyData = [...allTask];
    let selSearch = filterData.filter((d) => d.value);
    if (selSearch.length > 0) {
      let filterArray = [];
      copyData.map((res) => {
        let flagArray = [];
        selSearch.map((filter) => {
          if (res[filter.key]) {
            let flag =
              res[filter.key]
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) > -1;
            if (flag) {
              flagArray.push(flag);
            }
          }
        });
        if (flagArray.length === selSearch.length) {
          filterArray.push(res);
        }
      });
      sortStatusByData(filterArray);
    } else {
      sortStatusByData(copyData);
    }
  }, [filterData]);

  const sortStatusByData = (data) => {
    let sortData = data.sort((a, b) => {
      return a.status - b.status;
    });
    setNonRoutingClientList(sortData);
  };

  return (
    <>
      <div className="w-100">
        <div className="card shadow-sm ctm-border-radius">
          <div className="card-body align-center">
            <h4 className="title card-title float-left mb-0">
              Non Routing Index
            </h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <Select
                  className="basic-single mr-2 w-100"
                  name="color"
                  options={clientList}
                  placeholder="Select Client.."
                  value={
                    formData.client_id
                      ? clientList.find((r) => r.value == formData.client_id)
                      : null
                  }
                  onChange={(e) =>
                    setFormData({ ...formData, client_id: e.value })
                  }
                />
              </div>
              <div className="col-md-4">
                <Select
                  className="basic-single w-100"
                  name="color"
                  options={options}
                  placeholder="Select Non Routing Type.."
                  value={
                    formData.lookup_id
                      ? options.find((r) => r.value == formData.lookup_id)
                      : null
                  }
                  onChange={(e) =>
                    setFormData({ ...formData, lookup_id: e.value })
                  }
                />
              </div>
              <div className="col-md-4">
                <button
                  className="btn btn-theme ctm-border-radius text-white"
                  onClick={saveData}
                  disabled={!isValidForm()}
                >
                  Save
                </button>
                {componentRef && (
                  <ReactToPrint
                    content={() => componentRef}
                    trigger={() => {
                      return (
                        <button
                          style={{ borderBottom: "0px" }}
                          className="btn btn-danger text-white ctm-border-radius float-right mr-3"
                        >
                          <AiOutlinePrinter size={25} />
                        </button>
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100">
        <div className="card shadow-sm ctm-border-radius">
          <div className="card-body">
            {loading ? (
              <Oval
                height={60}
                width={60}
                color="#7539ff"
                wrapperStyle={{ justifyContent: "center" }}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#7539ff"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              <table
                className="table custom-table table-bordered"
                ref={(el) => (componentRef = el)}
              >
                <thead>
                  <tr>
                    <th>Client Name</th>
                    <th>NonRoutine Task</th>
                    <th>Client No</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {filterData.map((row, kd) => {
                      return (
                        <td key={kd}>
                          <input
                            placeholder="Search..."
                            type="text"
                            style={{
                              width: "100%",
                              minWidth: "100px",
                              padding: "0px 8px",
                            }}
                            className="form-control form-control-sm"
                            name="name"
                            value={row.value}
                            onChange={(e) => {
                              let copyData = [...filterData];
                              let index = copyData.findIndex(
                                (d) => d.key === row.key
                              );
                              copyData[index].value = e.target.value;
                              setFilterData(copyData);
                            }}
                          />
                        </td>
                      );
                    })}
                    <td className="text-center"></td>
                  </tr>
                  {nonroutingclientList.map((res, key) => {
                    return (
                      <tr key={key}>
                        <td>{res.client.firm_name}</td>
                        <td>{res.lookup.key_name}</td>
                        <td>{res.client.person_one_no}</td>
                        <td>
                          {moment(res.created_at).format("DD/MM/YYYY hh:mm A")}
                        </td>
                        <td>
                          <div
                            className="col form-group pl-0 pr-0 ml-0 mr-0 mb-0"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {res.status == 1 ? (
                              <label style={{color: 'green'}}>Completed</label>
                            ) : (
                              <label style={{color: 'red'}}>Pending</label>
                            )}
                          </div>
                        </td>
                        <td style={{ textAlign: "center", width: "114px" }}>
                          {res.status == 0 ? (
                            <button
                              type="button"
                              className="btn btn-sm btn-red btn-action-table "
                              onClick={() => deleteNonRoutingEmployee(res)}
                            >
                              <AiOutlineDelete size={22} />
                            </button>
                          ) : null}
                          <button
                            type="button"
                            className="btn btn-sm btn-red btn-action-table"
                            onClick={() => {
                              handleShowNotes(res);
                            }}
                          >
                            <GrNotes size={22} />
                          </button>
                        {res.status== 0 && <button
                            type="button"
                            className="btn btn-sm btn-red btn-action-table "
                            onClick={() => {
                              setCurrentRow(res);
                              setShowTaskModal(true);
                            }}
                          >
                            <FaTasks size={22} />
                          </button>}
                        </td>
                      </tr>
                    );
                  })}
                  <NoteModal
                    firmType={"Non Routing"}
                    openNotes={shownotes}
                    note={currentRow}
                    onCloseNotes={() => setShowNotes(false)}
                  ></NoteModal>
                  <TaskModal
                    open={showTaskModal}
                    row={currentRow}
                    onCloseModal={(event) => {
                      setShowTaskModal(false);
                      event && getNonRoutingClient();
                    }}
                  />
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NonRoutine;
